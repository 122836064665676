import React from "react"
import classes from "./container.module.scss"

const Container = ({ children, className, maxWidth = 450, minHeight = 0 }) => {
  return (
    <>
      <div className={`${classes.wrapper} ${className}`} style={{ minHeight }}>
        <div
          className={`${classes.formContent}`}
          style={{ maxWidth: maxWidth }}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default Container
