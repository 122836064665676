import React, { useEffect, useState } from "react"
import Container from "../Container/Container"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { Spring, config } from "react-spring/renderprops"
import "react-circular-progressbar/dist/styles.css"
import "./attendance.css"
import Loader from "../Loader/Loader"

import classes from "./attendance.module.scss"

const patheticColor = "#fd151b"
const underColor = "#a61c3c"
const minColor = "#ffb30f"
const moderateColor = "#b21f66"
const muchColor = "#849324"

const AttendanceBox = ({ data, todayTimetable }) => {
  const [todayBunkableSubs, setTodayBunkableSubs] = useState([])
  const [todayEnteringSubs, setTodayEnteringSubs] = useState([])

  function getHours(arr, val) {
    var index = [],
      i = -1
    while ((i = arr.indexOf(val, i + 1)) != -1) {
      index.push(i + 1)
    }
    return index
  }

  useEffect(() => {
    if (todayTimetable) {
      const today = todayTimetable.day
      const timetable = todayTimetable.timetable.map(t => t.trim())

      let subsICanBunk = data.map(datum => {
        if (
          datum.bunkableClassesCount > 0 &&
          timetable.includes(datum.subject)
        ) {
          return datum
        }
      })

      let subsIMustEnter = data.map(datum => {
        if (
          datum.classesToAttendCount > 0 &&
          timetable.includes(datum.subject)
        ) {
          return datum
        }
      })

      subsICanBunk = subsICanBunk.filter(sub => {
        if (sub) {
          sub.hoursToday = getHours(timetable, sub.subject)
          return true
        }

        return false
      })

      subsIMustEnter = subsIMustEnter.filter(sub => {
        if (sub) {
          sub.hoursToday = getHours(timetable, sub.subject)
          return true
        }

        return false
      })

      setTodayBunkableSubs(subsICanBunk)
      setTodayEnteringSubs(subsIMustEnter)
    }
  }, [todayTimetable, data])

  return (
    <>
      <div className={classes.title}>
        My today{" "}
        {todayTimetable && (
          <span style={{ textTransform: "capitalize" }}>
            ({todayTimetable.day})
          </span>
        )}
        <hr />
      </div>
      {todayBunkableSubs.length > 0 && (
        <>
          <div className={classes.subTitle}>Can Bunk</div>
          {todayBunkableSubs.map((sub, index) => {
            return (
              <Container maxWidth={600}>
                <div
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    padding: "1rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {sub.subject} (
                  {sub.hoursToday.length !== 1 ? "hours" : "hour"}{" "}
                  {sub.hoursToday.map((hr, index, arr) => {
                    return "" + hr
                  })}
                  )
                </div>
                <div
                  style={{ textAlign: "left", padding: "1rem", paddingTop: 0 }}
                >
                  i can bunk <b>{sub.bunkableClassesCount}</b>{" "}
                  {sub.bunkableClassesCount > 1 ? "hours" : "hour"}
                </div>
              </Container>
            )
          })}
        </>
      )}
      {todayEnteringSubs.length > 0 && (
        <>
          <div className={classes.subTitle}>Must Attend</div>
          {todayEnteringSubs.map((sub, index) => {
            return (
              <Container maxWidth={600}>
                <div
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    padding: "1rem",
                    paddingBottom: "0.2rem",
                  }}
                >
                  {sub.subject} ({sub.hoursToday.length > 1 ? "hours" : "hour"}{" "}
                  {sub.hoursToday.map((hr, index, arr) => {
                    return "" + hr
                  })}
                  )
                </div>
                <div
                  style={{ textAlign: "left", padding: "1rem", paddingTop: 0 }}
                >
                  i need to attend <b>{sub.classesToAttendCount}</b>
                  {" more "}
                  {sub.classesToAttendCount !== 1 ? "hours" : "hour"}
                </div>
              </Container>
            )
          })}
        </>
      )}
      <br />
      <div className={classes.title}>
        Analysis
        <hr />
      </div>
      {data.map((datum, index) => {
        let themeColor = datum.isPathetic
          ? patheticColor
          : datum.isUnder
          ? underColor
          : datum.isMinimum
          ? minColor
          : muchColor

        const {
          totalClasses,
          classesAttended,
          bunkableClassesCount,
          classesToAttendCount,
        } = datum

        return (
          <Container maxWidth={600} key={index}>
            <div className={classes.card}>
              <div className={classes.percentContainer}>
                <Spring
                  from={{ percentage: 0 }}
                  to={{ percentage: datum.percentage }}
                  config={config.molasses}
                >
                  {({ percentage }) => (
                    <CircularProgressbar
                      value={percentage.toFixed()}
                      text={`${percentage.toFixed()}%`}
                      background
                      counterClockwise
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: themeColor,
                        strokeLinecap: "butt",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                      })}
                    ></CircularProgressbar>
                  )}
                </Spring>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 1rem",
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "1.4rem",
                    padding: "0.5rem 0",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                  }}
                >
                  {datum.subject}
                </div>
                <hr
                  style={{
                    height: "0.3rem",
                    borderRadius: "1rem",
                    backgroundColor: themeColor,
                    opacity: "0.4",
                    margin: 0,
                    marginBottom: "0.3rem",
                  }}
                />

                <div className={classes.numberContainer}>
                  <div>
                    <div>Hours I can bunk</div>
                    <div> : </div>
                    <div>{bunkableClassesCount}</div>
                  </div>

                  <div>
                    <div>Hours to attend</div>
                    <div> : </div>
                    <div>{classesToAttendCount}</div>
                  </div>

                  <div>
                    <div>Total hours</div>
                    <div> : </div>
                    <div>{totalClasses}</div>
                  </div>

                  <div>
                    <div>Attended hours</div>
                    <div> : </div>
                    <div>{classesAttended}</div>
                  </div>
                </div>

                <div style={{ color: themeColor }} className={classes.message}>
                  {datum.message}
                </div>
              </div>
            </div>
          </Container>
        )
      })}
    </>
  )
}

export default AttendanceBox
