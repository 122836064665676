import React, { useEffect } from "react"
import classes from "./loginform.module.scss"
import Container from "../Container/Container"

import { connect } from "react-redux"
import { mapStateToProps } from "../../reduxMap"
import { navigate } from "gatsby"

const style = { width: "85%", margin: "1rem" }

let LoginForm = ({ username, password, setUsername, setPassword, login }) => {
  return (
    <Container className={`${classes.fadeInDown}`} minHeight={"100vh"}>
      <h2 className={`${classes.active}`}> LogIn </h2>

      <form>
        <input
          type="text"
          id="login"
          className={`${classes.fadeIn} ${classes.second}`}
          name="login"
          placeholder="username"
          style={style}
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
        <input
          type="password"
          id="password"
          className={`${classes.fadeIn} ${classes.third}`}
          name="login"
          placeholder="password"
          style={style}
          onChange={e => setPassword(e.target.value)}
          value={password}
        />
        <input
          type="submit"
          className={`${classes.fadeIn} ${classes.fourth} ${classes.button}`}
          value="Log In"
          style={style}
          onClick={e => {
            e.preventDefault()
            login()
          }}
        />
      </form>

      <div className={`${classes.formFooter}`}>
        <a
          className={`${classes.underlineHover}`}
          href="mailto:etcool.attendance.manager@gmail.com?Subject=From%20webapp%20NSSCE"
        >
          etcool
        </a>
        <br />
        attendance analyser for nssce students
      </div>
    </Container>
  )
}

export default LoginForm
