import React from "react"

import classes from "./loadingscreen.module.scss"
import Loader from "../Loader/Loader"

const LoadingScreen = ({ loaderText = "Loading... Please wait dude." }) => {
  return (
    <div className={classes.root}>
      <div className={classes.loaderDiv}>
        <Loader strokeColor="#fff" strokeOpacity="1" />
        <br />
      </div>
      <div className={classes.loaderText}>{loaderText}</div>
    </div>
  )
}

export default LoadingScreen
