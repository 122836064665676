const mapDispatchToProps = dispatch => {
  return {
    setUser: (data, username, password) => {
      dispatch({
        type: "LOGGED_IN",
        payload: { data, username, password },
      })
    },
    logout: () => {
      dispatch({
        type: "LOGGED_OUT",
      })
    },
    setLoading: loadingText => {
      dispatch({
        type: "SET_LOADING",
        payload: { loadingText },
      })
    },
    stopLoading: () => {
      dispatch({
        type: "STOP_LOADING",
      })
    },
    openSnackbar: ({ snackbarText, snackbarType }) => {
      dispatch({
        type: "OPEN_SNACKBAR",
        payload: { snackbarText, snackbarType },
      })
    },
    closeSnackbar: () => {
      dispatch({
        type: "CLOSE_SNACKBAR",
      })
    },
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    loader: state.loading,
  }
}

export { mapDispatchToProps, mapStateToProps }
