/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import "./Layout.css"
import styles from "./layout.module.scss"
import Snackbar from "./Snackbar/Snackbar"
import LoginForm from "./LoginForm/LoginForm"
import AttendanceBox from "./AttendanceBox/AttendanceBox"
import Loader from "./Loader/Loader"
import LoadingScreen from "./LoadingScreen/LoadingScreen"
import { mapStateToProps } from "../reduxMap"
import { connect } from "react-redux"

let Layout = ({ children, loader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        // style={{
        //   margin: `0 auto`,
        //   maxWidth: 960,
        //   padding: `0px 1.0875rem 1.45rem`,
        //   paddingTop: 0,
        // }}
        className={styles.wrapper}
      >
        <main>{children}</main>
        {loader.loading && <LoadingScreen loaderText={loader.loadingText} />}
        <Snackbar text={"Hello There"} />
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </div>
    </>
  )
}

Layout = connect(mapStateToProps)(Layout)

export default Layout
